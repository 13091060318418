import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCurrentRoundSubmitted',
})
export class IsCurrentRoundSubmittedPipe implements PipeTransform {
  //TODO roundDetails.fully_submitted == 1; make it dynamic
  transform(value: any) {
    const roundDetails = value.rounds.find((round) => {
      return round.round_number == value.current_round;
    });
    if (roundDetails) {
      return roundDetails.fully_submitted == 1;
    } else {
      return false;
    }
  }
}
