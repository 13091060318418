<div class="h-100 overflow-hidden">
  <div class="sidemenu-container overflow-auto h-100">
    <div
      *ngIf="isMobileView"
      class="d-flex menu-close-icon justify-content-end"
    >
      <label
        #closeButton
        class="menu-bar-label cursor-pointer"
        for="primarySidebar"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            stroke="#949596"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.17 14.8299L14.83 9.16992"
            stroke="#949596"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.83 14.8299L9.17 9.16992"
            stroke="#949596"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </label>
    </div>
    <ul class="sidemenu-list">
      <li class="sidemenu-item" *ngFor="let item of items">
        <a
          class="sidemenu-link"
          [routerLink]="item.routerLink"
          routerLinkActive="active-menu"
          (click)="item.items ? toggleSubMenu(item) : closeSidebar()"
        >
          <span class="icon" [ngClass]="item.icon"></span>
          <span class="sidemenu-text">{{ item.label }}{{item.queryParams}}</span>
          <span
            *ngIf="item.items"
            class="icon icon-arrow-toggle"
            [ngClass]="{ expanded: item.expanded }"
          ></span>
        </a>
        <ul *ngIf="item.items && item.expanded" class="submenu-list">
          <li class="submenu-item" *ngFor="let subItem of item.items">
            <a
              class="submenu-link"
              [routerLink]="subItem.routerLink"
              routerLinkActive="active-menu"
              (click)="closeSidebar()"
            >
              <span class="icon" [ngClass]="subItem.icon"></span>
              <span class="submenu-text">{{ subItem.label }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <div *ngIf="isMobileView">
      <p-accordion class="d-block w-100" *ngIf="isCountryToggleButton && !isJudgesPortal">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <span class="flex align-items-center gap-2 w-full">
              <figure class="avatar m-0">
                <span class="fw-normal white-space-nowrap">{{
                  userData && (userData.firstName.charAt(0).toUpperCase() ?? "")
                }}</span>
              </figure>
              <span class="fw-normal white-space-nowrap">{{
                userData?.firstName ? userData?.firstName : userData.fullName
              }}</span>
            </span>
          </ng-template>

          <ul class="sidemenu-list">
            <li class="sidemenu-item" *ngFor="let item of navItems">
              <a
                class="sidemenu-link"
                href="#"
                routerLink="{{ item.routerLink }}"
                routerLinkActive="active-menu"
                (click)="closeSidebar()"
              >
                <span class="icon" [ngClass]="item.icon"></span>
                <span class="sidemenu-text">{{ item.label }}</span>
              </a>
            </li>
          </ul>
        </p-accordionTab>
      </p-accordion>
      <div *ngIf="!isCountryToggleButton">
        <span class="d-flex align-items-center gap-2 w-full">
          <figure class="avatar m-0">
            <span class="fw-normal white-space-nowrap">{{
              userData &&
                userData.length &&
                (userData.fullName.charAt(0).toUpperCase() ?? "")
            }}</span>
          </figure>
          <span class="fw-normal white-space-nowrap">{{
            userData?.firstName ? userData?.firstName : userData.fullName
          }}</span>
        </span>
      </div>
    </div>
    <!-- toggle -->
    <div
      *ngIf="
        (isCountryToggleButton || isOrganisationInsiderStartegy) &&
        !isJudgesPortal
      "
      class="toggle-wrapper px-3"
    >
      <input
        type="checkbox"
        (change)="toggleLanguage()"
        [checked]="isUK"
        id="switch"
      />
      <label for="switch">
        <span>US</span>
        <span>UK</span>
      </label>
    </div>

    <!-- Logout -->
    <div class="mt-auto">
      <p-button (click)="logout()" [text]="true" severity="danger">
        <div class="d-flex align-items-center logout-text">
          <span class="d-flex align-items-center">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0165 5.38948V4.45648C13.0165 2.42148 11.3665 0.771484 9.33146 0.771484H4.45646C2.42246 0.771484 0.772461 2.42148 0.772461 4.45648V15.5865C0.772461 17.6215 2.42246 19.2715 4.45646 19.2715H9.34146C11.3705 19.2715 13.0165 17.6265 13.0165 15.5975V14.6545"
                stroke="#E54F6D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.8096 10.0215H7.76855"
                stroke="#E54F6D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.8817 7.10645L19.8097 10.0214L16.8817 12.9374"
                stroke="#E54F6D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="ms-2">Logout</span>
        </div>
      </p-button>
    </div>
  </div>
</div>
