import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCurrentRound',
})
export class GetCurrentRoundPipe implements PipeTransform {
  transform(value: any, params: string) {
    const roundDetails = value.rounds.find((round) => {
      return round.round_number == value.current_round;
    });
    console.log(roundDetails, params);
    if (roundDetails) {
      return roundDetails[params];
    } else {
      return false;
    }
  }
}
